.c-container{
    justify-content: space-around;
    gap: 2rem;
}
.c-container>img{
    width: 9rem;
    color: rgb(104, 103, 103);
}
.c-wrapper{
    background-color: rgb(228, 226, 226);
}