.hero-wrapper{
    color: white;
    position: relative;
    padding-bottom: 2rem;
    width: 100%;
    /* height: 100vh; */
}

.hero-container{
    justify-content: space-evenly;
    align-items: flex-end;
}

.hero-left{
    gap: 3rem;
    margin-bottom: 10rem;
}

.hero-title{
    position: relative;
    z-index: 1;
   line-height: 4rem;
}
/* .hero-des{
    position: relative;
    z-index: 2;
   
} */

.orange-circle{
    height: 3rem;
    width: 3rem;
    background: var(--orange-gradient);
    border-radius: 999px;
    position: absolute;
    right: 19%;
    top: -12%;
    z-index: -1;
}

.search-bar{
    background-color: white;
    border: 3px solid rgba(255, 255, 255, 0.15);
    border-radius: 5px;
    padding: 0.2rem 0.9rem;
    justify-content: space-evenly;
    gap: 0.5rem;
}

.search-bar>input{
    border: none;
    outline: none;
}

.stats{
    width: 100%;
    justify-content: space-between;
}

.stat>:nth-child(1){
    font-size: 2rem;
}

.stat>:nth-child(1)>:last-child{
    color: orangered;
}

.image-container{
    margin-right: 5rem;
    /* margin-bottom: 10rem; */
    width: 17rem;
    height: 30rem;
    overflow: hidden;
    border-radius: 10rem 10rem 0 0;
    border: 8px solid rgba(255, 255, 255, 0.15);

}

.image-container>img{
    width: 100%;
    height: 100%;
}