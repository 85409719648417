.r-head{
    margin-bottom: 2rem;
}
.r-card{
    gap: 0.6rem;
    padding: 1rem;
    border-radius: 10px;
    max-width: max-content;
    margin: 0% auto;
    margin-bottom: 1rem;
    transition: all 300ms ease-in ;
    border: 2px solid black;
    outline: 2px solid darkgray;
}
.r-card>img{
    width: 100%;
    width: 12rem;
    margin: 0% auto;
}
.r-card>:nth-child(2){
    font-size: 1rem;
    font-weight: 600;
}
.r-card>:nth-child(3){
    font-size: 1.2rem;
    color: rgb(161, 161, 245);
}
.r-card>:nth-child(4){
    font-size: 0.7rem;
    width: 12rem;
}
.r-card:hover{
    scale: 1.025;
    cursor: pointer;
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(50, 50, 50, 0.46) 217.91%
    );
    box-shadow: 0px 72px 49px -51px rgba(136, 160, 255, 0.21);
  }
  .swiper-horizontal{
    overflow: visible;
  }
  .orangetext2{
    margin: 0% 20%;
}
.r-container{
    overflow: hidden;
    position: relative;
    background-color: rgb(240, 237, 237);
    /* background-image: url('./pictures/background.jpg'); */
    /* margin-bottom: 1rem; */
}
.r-button{
    position: absolute;
    font-size: 1.5rem;
    gap: 1rem;
    top: -4rem;
    right: 20px;
}
.r-button button{
    font-size: 1.2rem;
    padding: 0.2 rem 0.8rem;
    color: blue;
    border: none;
    border-radius: 5px;
    background-color: white;
    cursor: pointer;
}
.r-button>:nth-child(1)
{
    background-color: #EEEEFF;
    padding: 0.2rem;
    border-radius: 5px;
}
.r-button>:nth-child(2)
{
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.25);
    padding: 0.2rem;
    border-radius: 5px;


}