.inner-container{
    gap: 1.5rem;
    background: #4161df;
    padding: 2rem;
    border-radius: 10px;
    border: 6px solid #5d77d6;
    text-align: center;
    max-width: 70rem;
    margin-left: 4rem;
}
.inner-container .primarText{
    color: white;
    font-weight: 600;
}
.inner-container .secondaryText{
    color: rgba(255, 255, 255, 0.587);
}
.inner-container .button{
    border-radius: 10px;
    border: 2px solid white;
    background: #5a73d7;
    box-shadow: var(--shadow);
    /* color: #4161df; */
}
.g-wrapper{
    background-color: rgb(228, 226, 226);
}